import { notify } from './topbar';
import { getChangeMessage } from './orders';

export function notifyMonitored(orderNo, updated) {
  const message = getChangeMessage(updated, orderNo);
  const link = process.env.VUE_APP_URL + '/orders?search=' + orderNo;
  if (!message) return;
  notify({
    description: message,
    type: 'info',
    persistent: true,
    link: link,
  });
}
