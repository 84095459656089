/**
 * Facade for fetching and json-parsing response
 * @param {string} url
 * @param {string} method
 * @param {any?} body
 * @returns {Promise<any>} JSON Response
 */
export async function fetchJSON(url, method, body) {
  const token = (await getIdToken()) || getCookieToken();
  const headers = { authorization: token };

  const options = { method, headers };
  if (body) options['body'] = body;

  const response = await fetch(url, options);
  const result = await response.json();
  if (!response.ok) throw { status: response.status, body: result };
  return result;
}

export function getCookieToken(key = 'idToken') {
  let KEY_PREFIX = 'myMuntersTopbar';
  if (process.env.VUE_APP_STAGE !== 'prod') KEY_PREFIX += 'Test';

  const name = KEY_PREFIX + '__' + key;
  const match = document.cookie?.match(new RegExp(name + '=(.+?);'));
  return match?.[1] ?? null;
}

export async function getIdToken() {
  const topbar = document.querySelector('mymunters-topbar');
  if (!topbar) return null;

  try {
    const token = await topbar.getIdToken();
    return token;
  } catch (ex) {
    console.error(ex);
    return null;
  }
}

export async function getGroupById(id) {
  if (!id) return;
  const url = new URL(process.env.VUE_APP_IAM_API_URL + '/groups/' + id);
  try {
    const result = await fetchJSON(url, 'get');
    return result;
  } catch (err) {
    throw new Error(err.message);
  }
}

export async function getUserId() {
  const topbar = document.querySelector('mymunters-topbar');
  if (!topbar) return null;

  try {
    const user = await topbar.getUserInfo();
    return user.userId;
  } catch (ex) {
    console.error(ex);
    return null;
  }
}

export async function getUserPermissions() {
  const topbar = document.querySelector('mymunters-topbar');
  if (!topbar) return null;

  try {
    const user = await topbar.getUserInfo();
    return user.permissions;
  } catch (ex) {
    console.error(ex);
    return null;
  }
}

export async function getUserById(id) {
  if (!id) return;
  const url = new URL(process.env.VUE_APP_IAM_API_URL + '/users/' + id);
  try {
    const result = await fetchJSON(url, 'get');
    return result;
  } catch (err) {
    console.error(err.message);
  }
}

export async function deleteTheComment(uuid, orderNo, contract) {
  const input = {
    uuid,
    ORDER_NO: orderNo,
    CONTRACT: contract,
  };
  const send = JSON.stringify(input);
  const url = new URL(process.env.VUE_APP_API_URL + '/comment');
  try {
    const result = await fetchJSON(url, 'delete', send);
    return result;
  } catch (err) {
    console.error(err.message);
  }
}

export async function getTheEmailAddress(userId) {
  const input = JSON.stringify(userId);
  const url = new URL(process.env.VUE_APP_API_URL + '/email');
  try {
    const result = await fetchJSON(url, 'post', input);
    return result;
  } catch (err) {
    console.error(err.message);
  }
}
